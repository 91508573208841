<template>
	<v-navigation-drawer
		id="core-navigation-drawer"
		v-model="drawer"
		:dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
		:expand-on-hover="expandOnHover"
		:right="$vuetify.rtl"
		:src="barImage"
		mobile-breakpoint="960"
		app
		width="260"
		color="primary"
		v-bind="$attrs"
	>
		<template v-slot:img="props">
			<v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
		</template>

		<v-divider class="mb-1" />

		<v-list dense nav>
			<v-list-item three-line>
				<v-list-item-avatar class="align-self-center" color="white" contain>
					<v-img :src="profile.imgAvatar" />
				</v-list-item-avatar>
				<v-list-item-content>
					<v-list-item-title
						class="text-h6"
						v-text="profile.title"
					></v-list-item-title>
					<v-list-item-subtitle
						class="text-h8"
						v-text="profile.role"
					></v-list-item-subtitle>
					<v-list-item-subtitle
						class="text-h7"
						v-text="profile.subtitle"
					></v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
		</v-list>

		<v-divider class="mb-2" />

		<v-list expand nav>
			<!-- Style cascading bug  -->
			<!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
			<div />

			<v-list expand nav>
				<template v-for="(item, i) in items">
					<base-item-group
						v-if="item.children"
						:key="`group-${i}`"
						:item="item"
					>
					</base-item-group>
					<base-item v-else :key="`item-${i}`" :item="item" />
				</template>
			</v-list>

			<!-- Style cascading bug  -->
			<!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
			<div />
		</v-list>
	</v-navigation-drawer>
</template>

<script>
	// Utilities
	import { mapState } from "vuex";
	//import { mapActions } from "vuex";

	export default {
		name: "DashboardCoreDrawer",

		props: {
			expandOnHover: {
				type: Boolean,
				default: false,
			},
		},

		data: () => ({
			items: [],
			itemsAdministrador: [
				{
					icon: "mdi-view-dashboard",
					title: "Dashboard",
					to: "/",
				},
				{
					title: "Administracion",
					group: "/admin",
					children: [
						{
							title: "Tipo De Usos",
							to: "tipo_de_usos",
							icon: "mdi mdi-database",
						},
						{
							title: "Tipo de Certificados",
							to: "tipo_certificados",
							icon: "mdi mdi-database",
						},
						{
							title: "Tipo de Operaciones",
							to: "tipo_operaciones",
							icon: "mdi mdi-database",
						},
						{
							title: "Compañias Seguro",
							to: "aseguradoras",
							icon: "mdi mdi-database",
						},
						{
							title: "Concesionarios",
							to: "concesionarios",
							icon: "mdi mdi-database",
						},
					],
				},
				{
					title: "Personas",
					group: "/personas",
					children: [
						{
							title: "Personas Físicas",
							to: "personas_fisicas",
						},
						{
							title: "Personas Jurídicas",
							to: "personas_juridicas",
						},
					],
				},
				{
					title: "Trámites",
					group: "/tramites",
					children: [
						{
							title: "Solicitud Tipo 01 Autos",
							to: "f01_autos",
							icon: "mdi mdi-alpha-f",
						},
						{
							title: "Solicitud Tipo 01 MAVI",
							to: "f01_mavi",
							icon: "mdi mdi-alpha-f",
						},
					],
				},

				{
					title: "Mensajes",
					group: "/mensajes",
					children: [
						{
							title: "Noticias",
							to: "noticias",
							icon: "mdi mdi-newspaper",
							class: "blue lighten-2",
							cant: "",
						},
						{
							title: "Recibidos ",
							to: "recibidos",
							icon: "mdi mdi-email",
							class: "red lighten-2",
							cant: "",
						},
						{
							title: "Recibidos Leidos",
							to: "recibidos_leidos",
							icon: "mdi mdi-email-open",
							cant: "",
						},
						{
							title: "Enviados",
							to: "enviados",
							icon: "mdi mdi-email-arrow-right",
							cant: "",
						},
					],
				},
			],
			itemsSeguridad: [
				{
					icon: "mdi-view-dashboard",
					title: "Dashboard",
					to: "/dashboard",
				},
				{
					title: "Seguridad",
					icon: "",
					group: "/admin",
					children: [
						{
							title: "Usuarios",
							to: "usuarios",
						},
						{
							title: "Permisos",
							to: "permission",
						},
						{
							title: "Roles",
							to: "role",
						},
						{
							title: "Configuraciones",
							to: "configuraciones",
						},
						{
							title: "Auditoria",
							to: "auditoria",
						},
						{
							title: "Firewall",
							to: "firewall",
						},
					],
				},
			],
		}),

		computed: {
			...mapState(["barColor", "barImage"]),
			drawer: {
				get() {
					return this.$store.state.drawer;
				},
				set(val) {
					this.$store.commit("SET_DRAWER", val);
				},
			},
			computedItems() {},

			profile() {
				return {
					avatar: true,
					imgAvatar: "/img/acara_logo_2.png",
					/*imgAvatar:
																																																																																																	          "https://ui-avatars.com/api/?name=" + this.$store.state.user.role,*/
					//title: this.$store.state.user.name,
					title: this.$keycloak.tokenParsed.preferred_username,
					//subtitle: "MATRICE LOGIN",
					subtitle: this.$keycloak.clientId.toUpperCase(),
					//role: "Perfil:" + this.$store.state.user.role,
					role:
						this.$keycloak.tokenParsed.given_name +
						" " +
						this.$keycloak.tokenParsed.family_name,
				};
			},
		},
		created() {
			let data = {};
			this.setNavDrawer();
			this.mensajes();
		},
		methods: {
			/*...mapActions({
																																																																																																	      signIn: "login",
																																																																																																	      signOut: "logout",
																																																																																																	    }),*/
			setNavDrawer() {
				//this.signIn();
				//console.log(this.$store.state.user);
				/*
																																																																																																	      if (this.$keycloak.hasResourceRole("administrador")) {
																																																																																																	        this.items = this.itemsAdministrador;
																																																																																																	      }
																																																																																																	      if (this.$keycloak.hasResourceRole("seguridad")) {
																																																																																																	        this.items = this.itemsSeguridad;
																																																																																																	      }
																																																																																																	      */
				//console.log(this.is("administrador"));
				if (this.is("administrador") || this.is("usuario")) {
					this.items = this.itemsAdministrador;
				}
				if (this.is("seguridad")) {
					this.items = this.itemsSeguridad;
				}

				return this.items.map(this.mapItem);
			},
			mapItem(item) {
				return {
					...item,
					children: item.children ? item.children.map(this.mapItem) : undefined,
					title: this.$t(item.title),
				};
			},
			modificarTitleMenu(to, cant) {
				this.items[4].children.forEach((item) => {
					if (item.to === to) {
						//item.title = newTitle;
						item.cant = cant;
					}
				});
			},
			mensajes() {
				let cant = "";
				this.$axiosApi
					.get("mensajes_recibidos")
					.then((r) => {
						if (r.data.data.length > 0) {
							cant = r.data.data.length;
							this.modificarTitleMenu("recibidos", cant);
						}
					})
					.catch(function (error) {});
				let cantN = "";
				this.$axiosApi
					.get("noticias")
					.then((r) => {
						if (r.data.data.length > 0) {
							cantN = r.data.data.length;
							this.modificarTitleMenu("noticias", cantN);
						}
					})
					.catch(function (error) {});

				let cantE = "";
				let cont = 0;
				this.$axiosApi
					.get("mensajes_enviados")
					.then((r) => {
						if (r.data.data.length > 0) {
							r.data.data.forEach((item) => {
								if (item.estado === "enviado") {
									cont++;
								}
							});

							if (cont > 0) cantE = cont;
							this.modificarTitleMenu("enviados", cantE);
						}
					})
					.catch(function (error) {});
			},
		},
	};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px
</style>
